<template>
    <div>
        <b-card>
            <b-card-body>
                <b-form-group>
                    <label>File</label>
                    <b-form-file v-model="file" size="lg" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <b-button size="lg" block variant="success" class="mt-2" @click="submitHandler" :disabled="isDisabledBtn">
                        <b-icon-upload></b-icon-upload>
                        <span>Upload</span>
                    </b-button>
                </b-form-group>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { addJob } from "@/services/JobService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCol, BForm, BFormFile, BFormGroup, BIconUpload, BRow } from 'bootstrap-vue';
import { ValidationObserver } from "vee-validate";

export default {
    name: "AddJob",
    components: {
        ValidationObserver,
        BRow,
        BForm,
        BCol,
        BFormFile,
        ToastificationContent,
        BCardBody,
        BCard,
        BButton,
        BIconUpload,
        BFormGroup
    },
    data() {
        return {
            isDisabledBtn: false,
            file: null
        }
    },
    methods: {
        async submitHandler() {
            if (!this.file) return;
            this.isDisabledBtn = true;
            try {
                await addJob(this.file);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                        message: 'Customers added successfully!'
                    }
                });
                this.$router.push('/job');
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        message: "Error adding customers!"
                    }
                });
            }
            finally {
                this.isDisabledBtn = false;
            }
        }
    }
}
</script>